import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// Supprimez BrowserAnimationsModule de ce fichier pour éviter le double chargement.

import { SidebarComponent } from './components/sidebar/sidebar.component';
import { RouterModule } from '@angular/router';
import { PrimengModule } from './primeng.module';
import { TopbarComponent } from './components/topbar/topbar.component';

@NgModule({
  declarations: [
    SidebarComponent,
    TopbarComponent
  ],
  imports: [
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    PrimengModule
  ],
  exports:[
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PrimengModule,
    SidebarComponent,
    TopbarComponent
  ]
})
export class SharedModule { }
