import { Injectable } from '@angular/core';
import { ApiService } from '../../api/api.service';
import { ConsoleService } from '../../console/console.service';

@Injectable({
  providedIn: 'root'
})
export class SettingService {

  settings: any = null;

  constructor(
    private _apiService: ApiService,
    private _consoleService: ConsoleService,
  ) { }

  all() {
    this._apiService.get('settings').subscribe((data: any) =>{
      this.settings = data.data;
    });
  }

  getByKey(key){
    this._consoleService.debug('SettingService - getByKey', 'b', 'this.settings', this.settings);
    if(this.settings){
      return parseInt(this.settings.filter(setting => setting.label == key)[0].value);
    }

    return 0;
  }
}
