import { Component, OnInit } from '@angular/core';
import { SidebarService } from '../../services/sidebar/sidebar.service';
import { AuthService } from '../../services/auth/auth.service';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrl: './topbar.component.scss'
})
export class TopbarComponent implements OnInit {

  user: any;

  constructor(
    public sidebarService: SidebarService,
    private _authService: AuthService,
  ) {

  }

  ngOnInit() {
    this.user = this._authService.authenticated();
  }

  logout(){
    this._authService.logout();
  }
}
