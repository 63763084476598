<div class="layout-topbar">
  <div class="topbar-start">
    <button type="button" class="topbar-menubutton p-link p-trigger" (click)="sidebarService.toggle()"><i class="pi pi-bars"></i></button>
    <!-- <app-breadcrumb class="topbar-breadcrumb">
      <nav class="layout-breadcrumb">
        <ol>
          <li class="ng-star-inserted">E-Commerce Dashboard</li>
        </ol>
      </nav>
    </app-breadcrumb> -->
  </div>
  <div class="topbar-end">
    <ul class="topbar-menu">
      <!-- <li class="topbar-search">
        <span class="p-input-icon-left">
          <i class="pi pi-search"></i>
          <input type="text" pinputtext="" placeholder="Search" class="p-inputtext p-component p-element w-12rem sm:w-full">
        </span>
      </li> -->
      <!-- <li class="ml-3">
        <button pbutton="" type="button" icon="pi pi-cog" class="p-element p-button-text p-button-secondary p-button-rounded flex-shrink-0 p-button p-component p-button-icon-only">
          <span class="p-button-icon pi pi-cog" aria-hidden="true"></span>
        </button>
      </li> -->
      <li class="topbar-profile">
        <div class="dropdown">
          <a class="d-flex align-items-center text-white text-decoration-none dropdown-toggle" id="dropdownUser1" data-bs-toggle="dropdown" aria-expanded="true">
              <img src="https://github.com/mdo.png" alt="hugenerd" width="30" height="30" class="rounded-circle">
          </a>
          <ul class="dropdown-menu dropdown-menu-dark text-small" id="dropdownUser1">
              <li><a class="dropdown-item" href="#">Profil</a></li>
              <li>
                  <hr class="dropdown-divider">
              </li>
              <li><a class="dropdown-item" (click)="logout()">Déconnexion</a></li>
          </ul>
      </div>
      </li>
    </ul>
  </div>
</div>
